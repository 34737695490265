/** @jsx jsx */
import { jsx, Container, Flex, Box, Styled } from "theme-ui"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Checkmark from "../images/icons/check.inline.svg"

const Attorney = ({ data: { attorney, file } }) => {
  return (
    <Layout>
      <section
        sx={{
          py: 5,
          backgroundImage: `url("${file.publicURL}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          mb: 6
        }}>
        <Container>
          <Flex>
            <Box
              sx={{
                position: 'relative',
                width: ['full', '1/3', '1/4']
              }}>
              <img
                src={attorney.profile.publicURL}
                alt={attorney.name}
                sx={{
                  position: 'absolute',
                  borderRadius: 'full',
                  width: '245px',
                  boxShadow: 'lg',
                  top: '-20px'
                }} />
            </Box>
            <Box
              sx={{
                width: ['full', '7/12', '2/3'],
                ml: 'auto'
              }}>
              <Styled.h1>
                {attorney.name}
              </Styled.h1>
              <Styled.h5
                sx={{
                  fontWeight: '300'
                }}>
                {attorney.title}
              </Styled.h5>
            </Box>
          </Flex>
        </Container>
      </section>
      <Box
        as="section"
        sx={{
          pt: 3,
          pb: 5
        }}>
        <Container>
          <Flex
            sx={{
              flexWrap: 'wrap'
            }}>
            <Box
              sx={{
                width: ['full', '1/3', '1/4'],
                pr: 2
              }}>
              <Flex
                sx={{
                  flexDirection: 'column',
                }}>
                <Styled.h3 sx={{
                  mb: 3,
                  mt: 0
                }}>Practice Areas</Styled.h3>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    p: 0,
                    m: 0,
                    listStyle: 'none'
                  }}
                  as="ul">
                  {attorney.practice.map(node => (
                    <li key={node.id}>
                      <Link
                        sx={{
                          display: 'flex',
                          width: 'full',
                          p: 2,
                          color: 'primary',
                          borderStyle: 'solid',
                          borderColor: 'gray.2',
                          borderWidth: '0 0 1px'
                        }}
                        to={`/${node.slug}`}>
                        <Checkmark sx={{ width: '15px' }} />
                        <span sx={{ ml: 3 }}>{node.name}</span>
                      </Link>
                    </li>
                  ))}
                </Flex>
              </Flex>
            </Box>
            <Flex
              sx={{
                flexDirection: 'column',
                width: ['full', '7/12', '2/3'],
                ml: 'auto'
              }}>
              <Box
                sx={{
                  mb: 5
                }}>
                <Styled.h5
                  sx={{
                    color: 'primary',
                    mb: 3
                  }}>
                  {attorney.name}
                </Styled.h5>
                <p sx={{ m: 0 }}>{attorney.bio}</p>
              </Box>
              <Box
                sx={{
                  mb: 5
                }}>
                <Styled.h5
                  sx={{
                    color: 'primary',
                    mb: 3
                  }}>
                  Education
                  </Styled.h5>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    p: 0,
                    m: 0,
                    listStyle: 'none'
                  }}
                  as="ul">
                  {attorney.education.map((ed, i) => (
                    <li
                      sx={{
                        display: 'flex',
                        width: 'full'
                      }}
                      key={i}>
                      <Checkmark sx={{ width: '15px' }} />
                      <span sx={{ ml: 3 }}>{ed}</span>
                    </li>
                  ))}
                </Flex>
              </Box>
              <Box 
                sx={{
                  mb: 5
                }}>
                <Styled.h5
                  sx={{
                    color: 'primary',
                    mb: 3
                  }}>
                  Experience & Honors
                  </Styled.h5>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    p: 0,
                    m: 0,
                    listStyle: 'none'
                  }}
                  as="ul">
                  {attorney.experience.map((ex, i) => (
                    <li
                      sx={{
                        display: 'flex',
                        width: 'full'
                      }}
                      key={i}>
                      <Checkmark sx={{ width: '15px' }} />
                      <span sx={{ ml: 3 }}>{ex}</span>
                    </li>
                  ))}
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!){
    attorney(id: { eq: $id}){
      name
      title
      bio
      profile {
        publicURL
      }
      practice {
        id
        name
        slug
      }
      education
      experience
    }
    file(name: { eq: "office-white" }){
      publicURL
    }
  }
`

export default Attorney